import { OwlOptions } from 'ngx-owl-carousel-o/lib/models/owl-options.model';

export const PLAYLIST = {
  MLB: {
    highlights: [
      {
        id: 'PLL-lmlkrmJalROhW3PQjrTD6pHX1R0Ub8',
      },
    ],
    freepicks: [
      {
        id: 'PLyajoM1QsaiMhZAMTRAIHYd6nJdWLY3BZ',
      },
    ],
  },
  NBA: {
    highlights: [
      {
        name: 'Playoff',
        // id: 'PLlVlyGVtvuVnbJc91XH9Xs5vplsOGjDAf',
        id: 'PLlVlyGVtvuVkIjURb-twQc1lsE4rT1wfJ',
      },
      {
        name: 'Nightly Full Game',
        id: 'PLlVlyGVtvuVkIjURb-twQc1lsE4rT1wfJ',
      },
    ],
    freepicks: [
      {
        id: 'PLyajoM1QsaiOl2YYfrs_8Ma3FqrP43d8O',
      },
    ],
  },
  NHL: {
    highlights: [
      {
        name: 'Playoffs',
        id: 'PLCMvuMNehBkcs6DU8ZteyAxX7W26JNG86',
      },
      {
        name: 'Highlights',
        // id: 'PL1NbHSfosBuHInmjsLcBuqeSV256FqlOO',
        id: 'PL1NbHSfosBuG_HB8WTgFaCVsDWCcnOsUW',
      },
    ],
    freepicks: [
      {
        id: 'PLyajoM1QsaiMrGvdIalCQJg9NVcbQRDEP',
      },
    ],
  },
  Soccer: {
    highlights: [
      {
        name: 'MLS',
        id: 'PLcj4z4KsbIoXqILtp9POqCqhTzbta9QPb',
      },
      {
        name: 'EPL',
        id: 'PLQ_voP4Q3cfe9IYlxqCwGFFFTfQ29wVc9',
      },
    ],
    freepicks: null,
  },
  NFL: {
    highlights: [
      {
        page: 'NFL',
      },
    ],
    freepicks: [
      {
        id: 'PLyajoM1QsaiNhVsFvcZ9PJ4qUF9q0znLx',
      },
    ],
  },
  NCAAF: {
    highlights: [
      {
        page: 'UCi-INxdmFSrTrT95xU0sBpg',
      },
    ],
    freepicks: null,
  },
  NCAAB: {
    highlights: null,
    freepicks: [
      {
        id: 'PLyajoM1QsaiMqC2fRUBt7zPsALcWhJgxb',
      },
    ],
  },
  MMA: {
    highlights: null,
    freepicks: [
      {
        id: 'PLyajoM1QsaiPpVOuw8_phMQWoJU0St835',
      },
    ],
  },
  Boxing: {
    highlights: null,
    freepicks: null,
  },
  Cricket: {
    highlights: null,
    freepicks: null,
  },
  NRL: {
    highlights: null,
    freepicks: null,
  },
  AFL: {
    highlights: null,
    freepicks: null,
  },
  Tennis: {
    highlights: null,
    freepicks: null,
  },
  general: {
    highlights: null,
    freepicks: null,
  },
};

export const PLAYSTORE =
  'https://play.google.com/store/apps/details?id=com.kingpin&hl=en_IN&gl=US';
export const APPSTORE =
  'https://apps.apple.com/app/apple-store/id1390339038?pt=119107053&ct=app_tracking&mt=8';

export const DOWNLOADAPP = `https://kingpin.pro/downloadapp`;

export const MENUS = [
  {
    title: 'Home',
    link: '',
    type: 'internal',
    exact: true,
  },
  {
    title: 'Expert Picks',
    link: 'expert-picks',
    type: 'internal',
  },
  {
    title: 'Place Picks',
    link: 'place-picks',
    type: 'internal',
  },
  {
    title: 'Top Experts',
    link: 'top-bettors',
    type: 'internal',
  },
  {
    title: 'My Experts',
    link: 'my-experts',
    type: 'internal',
  },
  {
    title: 'Chat',
    link: 'chat/latest',
    type: 'internal',
  },
  // {
  //   title: 'Android App',
  //   link: PLAYSTORE,
  //   type: 'external',
  // },
  // {
  //   title: 'iOS App',
  //   link: APPSTORE,
  //   type: 'external',
  // },
  {
    title: 'Articles',
    link: 'free-picks/articles',
    type: 'internal',
  },
  // {
  //   title: 'Highlights',
  //   link: '/highlights',
  //   type: 'internal',
  // },
  {
    title: 'How it Works',
    link: '/how-it-works',
    type: 'internal',
  },
  {
    title: 'FAQ',
    link: '/faq',
    type: 'internal',
  },
  {
    title: 'Pricing',
    link: '/subscription',
    type: 'internal',
  },
];

export const LIVECROUSELOPTION: OwlOptions = {
  loop: false,
  margin: 10,
  autoWidth: true,
  autoplay: true,
  // autoplayTimeout: 1000,
  autoplaySpeed: 2000,
  fluidSpeed: true,
  // smartSpeed: 500,
  responsive: {
    0: {
      items: 2,
    },
    600: {
      items: 4,
    },
    1000: {
      items: 6,
    },
    1092: {
      items: 7,
    },
  },
};
export const SIBSCRIPTION_FEATURES_SLIDER: OwlOptions = {
  loop: false,
  mouseDrag: true,
  touchDrag: true,
  pullDrag: true,
  dots: false,
  margin: 20,
  navText: ['', ''],
  nav: false,
  responsive: {
    0: {
      items: 1,
      autoWidth: true,
    },
    767: {
      items: 2,
      autoWidth: true,
    },
    992: {
      items: 3,
      autoWidth: true,
    },
    1200: {
      items: 4,
      autoWidth: false,
    },
  },
};
export const ALL_GAMES: OwlOptions = {
  loop: false,
  mouseDrag: true,
  touchDrag: true,
  pullDrag: true,
  dots: false,
  margin: 20,
  navText: ['<', '>'],
  nav: false,
  responsive: {
    0: {
      items: 1,
      autoWidth: true,
    },
    767: {
      items: 2,
      autoWidth: true,
    },
    992: {
      items: 3,
      autoWidth: true,
    },
    1200: {
      items: 4,
      autoWidth: false,
    },
  },
};
